import React from 'react';
// import SignUp from "./SignUp"


const BlogPost = ({post}) => {
  console.log(post)
  return (
    <section className="blog-container">
      <div className="blog-content">
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>      
    </section>
  )
};

export default BlogPost;


