import React from 'react';


const BlogPostTitle = ({post}) => {
  console.log(post)
  return (
    <section className="blog-title-container">
      <div className='title-text'>{post.frontmatter.title}</div>
    </section>
  )
};

export default BlogPostTitle;


