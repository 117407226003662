import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout";
import BlogPost from "../components/BlogPost";
import BlogPostTitle from "../components/BlogPostTitle";



export default function BlogPostTemplate({data}) {
  // const data = props.data
  // console.log(data)
  // const post = data.markdownRemark
  return (
    <Layout>
      <BlogPostTitle post={data.markdownRemark}/>
      <BlogPost post={data.markdownRemark}/>
      <div className="banner-decor"></div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
      }
    }
  }
`